import React from "react";
import { Link } from "react-router-dom";
import { Form, Label, Input ,Button } from "reactstrap";



export default function MailchimpForm(){
  return (
    <>
    <link href="//cdn-images.mailchimp.com/embedcode/naked-10_7_dtp.css" rel="stylesheet" type="text/css"/>
    <div id="mc_embed_signup">
    <Form className="registration-form validate" action="https://beecoworking.us14.list-manage.com/subscribe/post?u=bd72dbc7e95516d84e84f2f5e&amp;id=86f837859c"
      method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">

      <div className="mc-field-group">
        <Label className="text-muted">
          Nome
        </Label>
        <Input type="text" name="FNAME" id="mce-FNAME" className="form-control mb-4 registration-input-box" />
      </div>

      <div className="mc-field-group">
        <Label className="text-muted">
          Cognome
        </Label>
        <Input type="text"  name="LNAME" id="mce-LNAME" className="form-control mb-4 registration-input-box" />
      </div>

      <div className="mc-field-group">
        <Label className="text-muted">
          Email
        </Label>
        <Input type="text" name="EMAIL" id="mce-EMAIL" className="form-control mb-4 registration-input-box" />
      </div>

      <div className="mc-field-group">
        <Label className="text-muted">
          Telefono
        </Label>
        <Input type="text" name="PHONE" id="mce-PHONE" className="form-control mb-4 registration-input-box" />
      </div>

      <div className="mc-field-group">
        <div className="form-check mb-4">
          <Input id="privacy" type="checkbox" className="" />
          <label className="form-check-label" htmlFor="privacy">
            Accetto <Link to="/termini-e-condizioni" target="_blank">termini e condizioni</Link>
          </label>
        </div>
      </div>


      <div id="mce-responses" className="clear foot">
        <div className="response" id="mce-error-response" style={{display:"none"}}></div>
        <div className="response" id="mce-success-response" style={{display:"none"}}></div>
      </div>
      <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
        <input type="text" name="b_bd72dbc7e95516d84e84f2f5e_86f837859c" tabIndex={-1}/>
      </div>

      <Button color="primary" className="btn btn-primary w-100" block>Registrati</Button>

    </Form>
    </div>
    </>
  )
}