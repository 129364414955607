import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";


// Modal Video
// import ModalVideo from "react-modal-video";

//import images
import HomeUrl from '../assets/images/home-border.png';
import MailchimpForm from "../component/MailchimpForm";

class Section extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
 
  openModal () {
    this.setState({isOpen: true})
  }

  render() {
    return (
      <React.Fragment>
        <section className="bg-home bg-light" id="home">
          <div className="home-center">
            <div className="home-desc-center">
              <Container>
                <Row className="align-items-center">

                  <Col lg={6}>
                    <div className="home-content">
                      <p className="mb-0">Coworking</p>
                      <img src={HomeUrl} height="15" alt="" />
                      <h1 className="home-title mt-4">BeeCoworking è la soluzione adatta a te!</h1>
                      
                      <p className="text-muted mt-4 f-20">
                        Necessiti di una o più postazioni di lavoro?
                      </p>

                      {/*
                      <p className="text-muted mt-4 f-20" style={{fontSize: "160%"}}>
                      Inoltre, puoi approfittare della promozione di BeeCoworking in versione <strong>Summer Edition</strong>: una postazione ad uso esclusivo in OPEN SPACE per i mesi di <strong>Giugno e Luglio</strong> a soli €320. 
                      </p>
                      * Ti assicuri una postazione e vieni quando vuoi. Promozione valida fino ad esaurimento posti. 
                      */}

                      {/*
                      <div className="mt-4 pt-2">
                        <Link to="#" className="btn btn-primary mr-3">Contact Us</Link>
                        <Link to="#" className="video-play-icon text-dark" onClick={this.openModal}>
                          <i className="mdi mdi-play-circle-outline text-dark mr-2"></i>Watch Intro Video
                          </Link>
                      </div>
                      */}
                    </div>
                  </Col>

                  <Col lg={5} className="offset-lg-1">
                    <div className="home-registration-form bg-white p-5 mt-4">
                      <h5 className="form-title mb-4 text-center font-weight-bold">Compila il form per essere contattato e ricevere maggiori informazioni</h5>

                      <MailchimpForm />

                    </div>
                  </Col>
                </Row>
              </Container>
              {/* <ModalVideo
                channel="vimeo"
                isOpen={this.state.isOpen}
                videoId="99025203"
                onClose={() => this.setState({ isOpen: false })}
              /> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
