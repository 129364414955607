import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
// import CountUp from 'react-countup';

import HomeUrl from '../assets/images/home-border.png';

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'technologies',
          icon: 'mdi-wifi',
          title: "Tecnologia",
          bullet_points: [
            "Internet in fibra ultraveloce",
            "Stampante",
            "Scanner",
            "Sala meeting con smart TV",
          ]
        },

        {
          id: 'relax',
          icon: 'mdi-coffee',
          title: "Relax",
          bullet_points: [
            "Area condizionata caldo/freddo",
            "Utenze e pulizie",
            "Area relax interna ed esterna",
            "Caffè ed acqua"
          ],
        },

        {
          id: 'chair',
          icon: 'mdi-chat',
          title: "Arredamento",
          bullet_points: [
            "Sedia ergonomica",
            "Armadietto con serratura",
            "Cassettiera",
            "Appendiabiti",
          ]
        },
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">Servizi offerti</h3>
                  <p className="text-muted f-17 mt-3">Scopri i servizi e i vantaggi dei nostri spazi. Pensiamo a tutto per farti stare bene.</p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className="services-icon bg-soft-primary">
                      <i className={"mdi text-primary " + item.icon}></i>
                    </div>

                    <h5 className="mt-4">{item.title}</h5>

                    {item.bullet_points.map((item, key) => 
                    <p className="text-muted my-0" key={key}>
                      <i className="mdi text-primary mdi-18px mdi-checkbox-marked-outline"></i>{" "}
                      {item}
                    </p>
                    )}
                    {/*
                    <div className="mt-3">
                      <Link to="#" className="text-primary f-16">Learn More <i className="mdi mdi-arrow-right ml-1"></i></Link>
                    </div>
                    */}

                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
