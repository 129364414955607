import React, { Component } from "react";

import { Col, Container, Row } from "reactstrap";

import Feature from '../assets/images/features/img-3.png';

export default class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="contact">
          <Container>
            {/*
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">Let's talk about everything!</h3>
                  <p className="text-muted f-17 mt-3">Vivamus ac nulla ultrices laoreet neque mollis mi morbi
                  elementum mauris
                            sit amet arcu <br /> fringilla auctor In eleifend maximus nisi sed vulputate.</p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            */}
            <Row className="mt-5 pt-4">
              <Col lg={6}>
                <div className="mt-4 home-img text-center">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>

                  <div className="title-box text-center mb-3">
                    <h3 className="title-heading mt-4"><i className="mdi mdi-map-marker"></i> Dove siamo</h3>
                    <strong>Viale dei Romagnoli, 1053 - Ostia Antica, Roma </strong>
                    <a href="https://goo.gl/maps/ukNoqK47mF6hNDdH8" target="_blank" rel="noreferrer">Apri Mappa</a>
                  </div>

                  <img src={Feature} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mt-4">
                  <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d290.4955085787989!2d12.316270716488004!3d41.7669012610979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1325f1a6e737ff31%3A0x7e92ec46b1498a68!2sViale%20dei%20Romagnoli%2C%201053%2C%2000119%20Roma%20RM!5e0!3m2!1sen!2sit!4v1645827802776!5m2!1sen!2sit" 
                  title ="Dove siamo" width="100%" height="400" style={{border:"1px solid #FFED00"}} allowFullScreen="" loading="lazy"></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
