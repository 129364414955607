import WhatsAppButtonStyle from './WhatsAppButton.module.css'

const api = (phone, text) => `https://wa.me/${phone}?text=${encodeURIComponent(text)}`

const url = api("3518033457", "Ciao, sono interessato a delle informazioni su BeeCoworking")

export default function WhatsAppButton(){
  return (
    <>
      <a href={url} className={WhatsAppButtonStyle.button} target="_blank" rel="noreferrer">
        <i className={`mdi mdi-whatsapp ${WhatsAppButtonStyle.icon}`}></i>
      </a>
    </>
  )
}