import React, { Component } from "react";

import { Col, Container, Row } from "reactstrap";

import Feature from '../assets/images/features/img-2.png';

export default class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="contact">
          <Container>
            {/*
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">Let's talk about everything!</h3>
                  <p className="text-muted f-17 mt-3">Vivamus ac nulla ultrices laoreet neque mollis mi morbi
                  elementum mauris
                            sit amet arcu <br /> fringilla auctor In eleifend maximus nisi sed vulputate.</p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            */}
            <Row className="mt-5 pt-4">
            <Col lg={6}>
                <div className="mt-4">

                <iframe width="100%" height="400" src="https://www.youtube-nocookie.com/embed/EYbAyCcGqKE?controls=0&modestbranding=1&autoplay=1&rel=0" 
                  title="BeeCoworking - Nuovi spazi di lavoro ad Ostia Antica " frameborder="0"
                  style={{border:"1px solid #FFED00"}}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div>
              </Col>

              <Col lg={6}>
                <div className="home-img text-center d-none d-lg-block d-xl-block mt-5">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  {/* <div className="title-box text-center mb-3">
                    <h3 className="title-heading mt-4"><i className="mdi mdi-map-marker"></i> Virtual tour</h3>
                    <a href="https://youtu.be/EYbAyCcGqKE" target="_blank" rel="noreferrer">Apri Video</a>
                  </div> */}

                  <img src={Feature} className="img-fluid" alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
