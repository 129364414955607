import React, { Component } from "react";
import { Container, Row, Col} from "reactstrap";
import FooterLink from "../Footer/Footer_link";

import Logo from '../../assets/images/logo-bw.png';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          id: 1,
          title: "Services",
          child: [
            { title: "Web Design", link: "#" },
            { title: "Lorem Test", link: "#" },
            { title: "Credit", link: "#" },
            { title: "Ui Design", link: "#" },
          ],
        },
        {
          id: 2,
          title: "Company",
          child: [
            { title: "Features", link: "#" },
            { title: "Faq", link: "#" },
            { title: "Contact us", link: "#" }
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer
          className="section bg-light bg-footer pb-5"
          id="footer"
        >
          <Container>
            <Row>
              <Col lg={{size: 5, offset: 1}}>
                <div className="footer-info mt-4">
                  <img src={Logo} alt="" height="22" />
                  <p className="text-muted mt-4 mb-2">Stare fuori dalla sede di lavoro non significa necessariamente rimanere a casa. Cambia il modo di lavorare. Scegli #BeeCoworking.</p>
                  <div className="team-social mt-4 pt-2">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/beecoworkingostiaantica" className="text-reset" target="_blank" rel="noreferrer" ><i className="mdi mdi-facebook"></i></a>
                      </li>{" "}
                      <li className="list-inline-item">
                      <a href="https://www.instagram.com/beecoworkingostiaantica" className="text-reset" target="_blank" rel="noreferrer" ><i className="mdi mdi-instagram"></i></a>
                      </li>{" "}
                      <li className="list-inline-item">
                      <a href="https://www.linkedin.com/company/beecoworking/" className="text-reset" target="_blank" rel="noreferrer" ><i className="mdi mdi-linkedin"></i></a>
                      </li>{" "}
                      <li className="list-inline-item">
                      <a href="https://www.youtube.com/channel/UCu1orOhSlMKZlTZQXhBUfpg" className="text-reset" target="_blank" rel="noreferrer" ><i className="mdi mdi-youtube"></i></a>
                      </li>{" "}
                    </ul>
                  </div>
                </div>
              </Col>
              
              <Col lg={6}>
                <Row className="pl-0 md-lg-5">
                  <Col lg={6}>
                    <div className="mt-4">
                      <h5 className="f-20">Orario di apertura</h5>
                      <div className="footer-link mt-3">
                      <strong>Dal Lunedì al Venerdì <br/>dalle 9.00 alle 19.00</strong><br/>
                      Chiuso sabato, domenica e festivi
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mt-4">
                      <h5 className="f-20">Contatti</h5>
                      <div className="footer-link mt-3">
                        <dt>E-Mail</dt>
                        <dd><a href="mailto:info@beecoworking.it">info@beecoworking.it</a></dd>

                        <dt>Cellulare</dt>
                        <dd><a href="tel:351.8033457">351.8033457</a></dd>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="my-5" />
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
