export default function TermsAndCondition() {
  return (
    <div className="mx-3 my-3">
      <h1>Termini e condizioni</h1>
      <p>
        Trattamento dei dati personali Informativa ai sensi dell'art. 13 del Codice
        della Privacy (pubblicato sulla Gazzetta Ufficiale 29 luglio 2003, Serie
        generale n. 174, Supplemento ordinario n. 123/L) Impegno la BeeCoworking, 
        titolare del trattamento dati, è attivamente impegnato nella
        salvaguardia dei dati personali dei propri utenti, e di chiunque affidi
        questi dati al nostro trattamento. Incoraggiamo i nostri utenti a leggere
        attentamente la presente informativa prima di utilizzare il sito. Finalità
        del trattamento Il trattamento dei dati verrà effettuato per: - dare la
        possibilità di accedere alle sezioni pubbliche e riservate del sito; - dare
        esecuzione all'attivazione e alla manutenzione di eventuali servizi
        sottoscritti o software on-line; - eseguire gli obblighi previsti da leggi o
        regolamenti; - la tutela del Titolare in sede giudiziaria; - inviare
        materiale informativo di natura tecnica, amministrativa o commerciale; -
        consentire il monitoraggio costante sull'efficacia del servizio proposto.
        Modalità del trattamento I dati verranno trattati principalmente con
        strumenti elettronici e informatici e memorizzati sia su supporti
        informatici che su supporti cartacei che su ogni altro tipo di supporto
        idoneo, nel rispetto delle misure minime di sicurezza ai sensi del
        Disciplinare Tecnico in materia di misure minime di sicurezza, Allegato B
        del Codice della Privacy. Specifiche misure di sicurezza sono osservate per
        prevenire la perdita di dati, usi illeciti o non corretti ed accessi non
        autorizzati.
      </p>
      <p>
        Tecnologie Si utilizzano le tecnologie di tracciamento degli indirizzi IP e
        dei cookie a scopo di tracciare le visite effettuate al proprio sito. 1.
        Indirizzi IP: sono raccolti gli indirizzi IP di tutti i visitatori che
        accedono al nostro sito. I dati raccolti sono utilizzati per la gestione
        delle operazioni tecnico amministrative della nostra azienda, la diagnosi di
        eventuali problemi tecnici e la prevenzione di intrusione e abuso del
        servizio. 2. Cookie: si usa la tecnologia "cookie" per migliorare le
        possibilità di utilizzo dei servizi offerti, associando un cookie al
        visitatore connesso. I cookie sono usati anche per determinare i valori di
        traffico del nostro sito e per tracciare il vostro profilo durante il
        processo di ordinazione dei servizi, per calcolare gli sconti e gestire le
        promozioni che eventualmente sono applicabili al vostro account. I
        visitatori del sito potranno disabilitare nel proprio browser la
        funzionalità di tracciamento dei cookie, ma in alcuni casi questo potrà
        comportare malfunzionamenti nell'uso del nostro sito.
      </p>
      <p>
        Natura obbligatoria Tutti i dati automatizzati richiesti sono obbligatori. I
        dati relativi alla registrazione sono facoltativi. Conseguenze del rifiuto
        dei dati In caso di mancato inserimento di uno o più dati obbligatori
        l'interessato non potrà godere dell'accesso al sito e del servizio
        richiesto. In tal caso l'utente è invitato ad abbandonare immediatamente il
        sito.
      </p>
      <p>
        Soggetti a cui potranno essere comunicati i dati personali I dati personali
        relativi al trattamento in questione possono essere comunicati anche a
        soggetti a cui sia riconosciuta la facoltà di accedere ai Vostri dati
        personali da norme di legge o di normative secondarie e/o comunitarie.
        Inoltre tali dati possono essere comunicati e diffusi agli operatori
        internet di cui BeeCoworking si avvale per la gestione dei
        propri domini. La diffusione dei dati raccolti è prevista sia in ambito
        nazionale sia all'estero.
      </p>
      <p>
        Diritti dell'interessato In relazione al trattamento di dati personali, ai
        sensi dell'art. 7 (Diritto di accesso ai dati personali ed altri diritti)
        del Codice della Privacy: 1. L'interessato ha diritto di ottenere la
        conferma dell'esistenza o meno di dati personali che lo riguardano, anche se
        non ancora registrati, e la loro comunicazione in forma intelligibile. 2.
        L'interessato ha diritto di ottenere l'indicazione: a) dell'origine dei dati
        personali; b) delle finalità e modalità del trattamento; c) della logica
        applicata in caso di trattamento effettuato con l'ausilio di strumenti
        elettronici; d) degli estremi identificativi del titolare, dei responsabili
        e del rappresentante designato ai sensi dell'articolo 5, comma 2; e) dei
        soggetti o delle categorie di soggetti ai quali i dati personali possono
        essere comunicati o che possono venirne a conoscenza in qualità di
        rappresentante designato nel territorio dello Stato, di responsabili o
        incaricati. 3. L'interessato ha diritto di ottenere: a) l'aggiornamento, la
        rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati; b)
        la cancellazione, la trasformazione in forma anonima o il blocco dei dati
        trattati in violazione di legge, compresi quelli di cui non è necessaria la
        conservazione in relazione agli scopi per i quali i dati sono stati raccolti
        o successivamente trattati; c) l'attestazione che le operazioni di cui alle
        lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda
        il loro contenuto, di coloro ai quali i dati sono stati comunicati o
        diffusi, eccettuato il caso in cui tale adempimento si riveli impossibile o
        comporti un impiego di mezzi manifestamente sproporzionato rispetto al
        diritto tutelato. 4. L'interessato ha diritto di opporsi, in tutto o in
        parte:
      </p>
      <p>
        a) per motivi legittimi al trattamento dei dati personali che lo riguardano,
        ancorché pertinenti allo scopo della raccolta; b) al trattamento di dati
        personali che lo riguardano a fini di invio di materiale pubblicitario o di
        vendita diretta o per il compimento di ricerche di mercato o di
        comunicazione commerciale. Altri dati Gli utilizzatori potranno depositare
        dati, tra cui testi, immagini, database e altri documenti di loro proprietà
        nello spazio web messo a disposizione. il Titolare non tratterà i dati che
        il cliente inserisce nel suo spazio web, che è un area pubblicamente
        disponibile tramite Internet. Anche se adottate le necessarie misure di
        sicurezza, i dati presenti su Internet sono per la loro natura dati
        pubblici. È vietato l'utilizzo dello spazio web per depositare e trattare
        dati sensibili on-line, per depositare dati che violano i diritti della
        privacy o di copyright. Titolare del trattamento dati Il titolare del
        trattamento dei dati è il Responsabile Dati, e-mail: info@beecoworking.it
        Per esercitare i diritti previsti all'art. 7 del Codice della Privacy, sopra
        elencati, l'interessato dovrà rivolgere richiesta scritta attraverso e-mail.
        L'e-mail va indirizzata a: info@beecoworking.it
      </p>
    </div>
  )
}