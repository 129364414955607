import React from "react";
import routes from "../src/routes";
import {
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

import "./assets/css/materialdesignicons.min.css";

import "./assets/scss/themes.scss";

import './App.css';

function App() {
  return (
    <React.Fragment>
        <Router>
          <Switch>
            {routes.map((route, idx) => (
              <Route path={route.path} component={route.component} key={idx} />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
  );
}

export default App;
